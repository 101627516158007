.product-gallery {
    display: block;
    max-width: 1600px;
    margin: 30px auto;

    @media (min-width: 768px) {
        margin: 35px 0 45px;
    }

    .product-anchor {
        flex-grow: 1;
        cursor: pointer;
        transition: all 0.6s ease-out;

        &:hover,
        &:focus {
            .swiper-image::after {
                opacity: 0.2;
                transition: all 0.6s ease-out;
            }

            p {
                color: #131313;
            }

            .product-brand {
                color: #5b5d5e;
            }

            .product-personalisation {
                color: #0000ed;
            }
        }
    }

    .swiper-image {
        margin-bottom: 13px;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        max-width: 380px;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #000;
            transition: all 0.3s ease-in-out;
            z-index: 1;
            opacity: 0.05;
        }

        img {
            max-width: 370px;
            max-height: 370px;
        }
    }

    .product-details {
        display: flex;
        flex-direction: column;
        color: #131313;
    }

    p {
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.3em;
    }

    .product-personalisation {
        color: #0000ed;
    }

    .product-price {
        font-size: 14px;
        padding-top: 2px;
    }

    .product-brand {
        font-size: 12px;
        color: #5b5d5e;
    }

    .swiper-button-next,
    .swiper-button-prev {
        display: none;
        background-color: #fff;
        background-size: cover;
        width: 45px;
        height: 45px;
        transition: all 0.25s ease-out;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
        border-radius: 0;
        transform: scale(1);
        top: 33%;

        &::after {
            content: "";
        }

        &:hover,
        &:focus {
            background-color: #0000ed;
            box-shadow: 0 4px 7px rgb(0 0 0 / 0.25);

            path {
                fill: #fff;
            }
        }
    }

    @media (min-width: 768px) {
        .swiper-button-next,
        .swiper-button-prev {
            display: block;
        }

        .swiper-scrollbar {
            width: 30%;
            margin: 50px 0 0;
        }
    }

    .swiper-button-prev {
        transform: scale(1) rotate(180deg);
        left: 15px;
    }

    .swiper-button-next {
        transform: scale(1) rotate(0);
        right: 15px;
    }

    .swiper-button-disabled {
        display: none !important;
    }

    .swiper-scrollbar {
        position: relative;
        left: 50%;
        z-index: 50;
        height: 1px;
        width: 50%;
        background-color: #d3d3d3;
        transform: translateX(-50%);
        padding: 1px 0;
        transition:
            all 0.25s ease-in,
            transform 0s;
        margin: 32px 0 0;

        .swiper-scrollbar-drag {
            background: #0000ed;
            cursor: pointer;
            height: 4px;
            margin: -2px 0;
            transition:
                all 0.25s ease-in,
                transform 0s;
            position: relative;
            border-radius: 10px;
            left: 0;
            top: 0;

            &:hover,
            &:focus,
            &:active {
                height: 8px;
                margin: -4px 0;
                transition:
                    all 0.25s ease-in,
                    transform 0s;
            }
        }
    }
}
