.store-finder-component {
    height: 100%;

    a {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
}
