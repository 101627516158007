#accountMenu ul.sidebar-nav li.SignOutAcc a::before {
    background-image: url("/images/core/global-sprite-sd-v4.svg");
}

#accountMenu {
    width: 100% !important;
    border: 1px solid #ccc;
    background-color: #fff;
}

#accountMenu ul.sidebar-nav li.topic {
    display: block;
}

#accountMenu a {
    display: block;
    font-size: 1.1em !important;
    padding: 10px 20px;
    color: #888;
    font-weight: bold;
}

#accountMenu a:hover,
#accountMenu a:active {
    display: block;
    color: #000;
}

#accountMenu ul.sidebar-nav li.topic:hover,
#accountMenu ul.sidebar-nav li.topic:active {
    background: none repeat scroll 0 0 #e6e4e6;
    position: relative;
}

#accountMenu ul.sidebar-nav li.FirstOptionAcc {
    font-size: 1.2em;
}

#accountMenu ul.sidebar-nav li.FirstOptionAcc::before {
    content: "";
    display: block;
    height: 10px;
}

#accountMenu ul.sidebar-nav li.FirstOptionAcc:first-child::before {
    display: none;
}

#accountMenu ul.sidebar-nav li.FirstOptionAcc a {
    font-weight: bold;
}

#accountMenu ul.sidebar-nav li.LastOptionAcc {
    border-bottom: 1px solid #ccc;
}

#accountMenu ul.sidebar-nav li.LastOptionAcc::after {
    content: "";
    display: block;
    height: 10px;
}

#accountMenu ul.sidebar-nav li.chevron-right {
    position: relative;
}

#accountMenu ul.sidebar-nav li.chevron-right .glyphicon {
    right: 8px;
    position: absolute;
    top: 10px;
}

#accountMenu ul.sidebar-nav li a {
    position: relative;
}

#accountMenu ul.sidebar-nav li a::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 7px;
    background-color: transparent;
}

#accountMenu ul.sidebar-nav li a:hover::before {
    background-color: #eee;
}

#accountMenu .sidebar-nav li.active a {
    color: #000;
}

#accountMenu .sidebar-nav li.active a::before {
    background-color: #ccc;
}

#accountMenu ul.sidebar-nav li.ImgButWrap.SignOutAcc {
    margin-top: 40px;
    width: 100%;
}

#accountMenu ul.sidebar-nav li.SignOutAcc a {
    background-color: #181816;
    color: #fff;
    text-align: center;
    font-weight: normal;
    font-size: 1.2em !important;
}

#accountMenu ul.sidebar-nav li.SignOutAcc a:hover {
    background-color: #333;
    color: #fff;
}

#accountMenu ul.sidebar-nav li.ImgButWrap.SignOutAcc a::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 25px;
    vertical-align: middle;
    position: static;
    margin-right: 5px;
    background-size: 840px 840px;
    background-position: -352px -435px;
}

#accountMenu ul.sidebar-nav li.ImgButWrap.SignOutAcc a:hover::before {
    background-color: transparent;
}

@media (max-width: 767px) {
    #accountMenu {
        margin-top: 10px;
    }
}

#topLinkMenu .TopLinkDrop .TopSubLinkMenu {
    display: block !important;
    transition: max-height 600ms linear 0s;
    max-height: 0;
    overflow: hidden;
}

#topLinkMenu:hover .TopLinkDrop .TopSubLinkMenu {
    height: auto;
    max-height: 300px;
}

.FooterSubGroup {
    background-color: #fff;
    margin: 0 0 6px;
    border: 0;
    min-height: 127px;
}

.FooterGroup1 {
    height: 263px;
}

.FooterSubGroup .FooterHeader {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    display: block;
    margin-bottom: 6px;
    width: 100%;
    font-size: 1.5em;
    line-height: 1.2em;
    font-weight: normal;
    float: left;
}

.root.FooterSubGroup::before {
    content: " ";
    display: table;
}

.root.FooterSubGroup::after {
    display: table;
    clear: both;
    content: " ";
}

.FooterHeader span {
    float: right;
    padding: 0 15px;
}

.FooterGroupLink {
    width: 50%;
    float: left;
    padding: 4px 0 0;
}

.FooterMenuWrap,
.FooterMenuWrap_v2 {
    width: 100%;
    float: left;
}

.FooterGroupLink li {
    line-height: 1.4em;
    padding: 2px 10px;
    text-align: left;
}

.FooterGroupLink li a {
    display: block;
    width: 100%;
}

.FooterGroupLink li a:hover {
    text-decoration: underline;
}

.FooterCardIcons,
.FooterSocialIcons {
    display: inline-block;
}

.FooterSocialIcons {
    padding: 12px 0 0 4px;
}

.FooterCardIcons {
    background: url("/images/payment/cardpayments-default.png") no-repeat 0 0 transparent;
    background-size: 100%;
    max-width: 418px;
    width: 100%;
    height: 50px;
}

.FooterSocialIcons li {
    float: left;
}

.FooterSocialIcons li a {
    height: 50px;
    float: left;
}

.SocialIcon-Facebook a {
    width: 49px;
}

.SocialIcon-Pinterest a {
    width: 45px;
}

.SocialIcon-Youtube a,
.SocialIcon-GooglePlus a,
.SocialIcon-Instagram a {
    width: 46px;
}

.SocialIcon-News a,
.SocialIcon-Twitter a {
    width: 47px;
}

.SocialIcon-InstagramMens a {
    width: 84px;
}

.SocialIcon-InstagramWomens a {
    width: 104px;
}

.FootdvSearch.dvSearch .TextBoxClear {
    right: 65px;
}

#FoottxtSearch {
    height: 43px;
    width: calc(100% - 55px);
    float: left;
    padding: 0 10px;
    border: 1px solid #ddd;
}

#FootcmdSearch::before {
    content: "\e003";
}

#FootcmdSearch {
    font-family: "Glyphicons Halflings", sans-serif;
    font-size: 20px;
    width: 55px;
    float: left;
    padding: 7px 4%;
    text-decoration: none;
}

.FootSearchDropDown {
    width: 75% !important;
    max-width: 300px;
    border-radius: 0 !important;
}

.FootSearchDropDown > li > a {
    font-size: 18px;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    color: #008d7f;
    padding-left: 25px;
}

.FootSearchDropDown > li {
    font-size: 18px;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    color: #000;
    padding: 5px 0;
}

.FootdvSearch {
    padding: 0 10px 15px;
}

@media (max-width: 1021px) {
    .FooterMenuWrap_v2 {
        display: none;
    }
}

@media (max-width: 767px) {
    .FooterSubGroup {
        min-height: 0;
        margin: 0;
        height: auto;
    }

    .FooterMenuWrap {
        display: none;
    }

    .FooterSubGroup .FooterHeader {
        margin-bottom: 0;
        cursor: pointer;
    }

    .FooterGroupLink li a {
        padding: 6px 0;
    }
}

@media (min-width: 768px) {
    .FooterMenuWrap {
        display: block !important;
    }

    nav#topMenu ul li.mobOnly,
    nav#topMenu ul li.root.noDesk {
        display: none;
    }
}

@media (min-width: 1022px) {
    .FooterSocialIcons li a {
        transition: all 400ms linear 0s;
    }

    .FooterMenuWrap_v2 {
        display: block !important;
    }
}

#helpMenu {
    font-size: 1em;
    color: #666;
    padding: 10px 10px 20px;
    display: block;
    background: url("/images/core/topheadgrad.png") repeat-x bottom left #f1f1f1;
}

#helpMenu h2 {
    font-size: 2.1em;
    font-weight: bold;
    padding: 5px;
    text-transform: capitalize;
}

#helpMenu .sidebar-nav {
    padding: 10px 0 0;
}

#helpMenu .sidebar-nav li {
    line-height: 1.3em;
    font-size: 1.1em;
    border-top: 1px solid #ddd;
}

#helpMenu .sidebar-nav li a {
    display: block;
    padding: 10px 5px;
}

@media (max-width: 767px) {
    #helpMenu .sidebar-nav li {
        font-size: 1.3em;
    }

    .shop > ul > li.deskOnly,
    .shop > ul > li.noMob {
        display: none;
    }
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

html {
    height: auto;
}

body,
.mp-container,
.scroller {
    height: 100%;
}

.mp-scroller {
    width: 100%;
}

.MobMenIcon {
    padding: 0 15px;
    position: inherit;
}

.mp-scroller,
.mp-scroller-inner {
    position: relative;
}

.mp-container {
    position: relative;
    overflow: hidden;
}

.menu-trigger {
    background-color: #fff;
    display: block;
    height: 55px;
    width: 55px;
    text-indent: -9999px;
    float: left;
}

.mp-pusher {
    position: relative;
    left: 0;
    height: 100%;
    transition: all 400ms linear 0s;
}

.mp-menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 75%;
    height: 100%;
    max-width: 280px;
    min-width: 233px;
    transform: translate(-100%, 0);
}

.mp-level {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translate(-100%, 0);
    display: none;
}

.mp-pusher::after,
.mp-level::after,
.mp-level::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    content: "";
    opacity: 0;
}

.mp-pusher::after,
.mp-level::after {
    background: rgba(0, 0, 0, 0.3);
}

.mp-level::after {
    z-index: -1;
}

.mp-pusher.mp-pushed::after,
.mp-level.mp-level-overlay::after {
    width: 100%;
    height: 100%;
    opacity: 1;
}

.mp-level.mp-level-overlay {
    cursor: pointer;
}

.mp-level.mp-level-overlay.mp-level::before {
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 1;
}

.mp-overlap .mp-level.mp-level-open {
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2);
    transform: translate(-40px, 0);
}

.mp-menu > .mp-level,
.mp-menu > .mp-level.mp-level-open,
.mp-menu.mp-overlap > .mp-level,
.mp-menu.mp-overlap > .mp-level.mp-level-open {
    box-shadow: none;
    transform: translate(0, 0);
}

.mp-menu > .mp-level a.menuitemtext {
    position: relative;
    float: none;
}

.mp-cover .mp-level.mp-level-open,
.mp-cover .mp-level.mp-level-opening {
    transform: translate(0, 0);
}

.mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open):not(.mp-level-opening) {
    transform: translate(-100%, 0);
}

.mp-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.menulevelheader {
    margin: 0;
    padding: 1em;
    color: rgba(0, 0, 0, 0.4);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    font-weight: 300;
    font-size: 2em;
}

.mp-menu.mp-cover .menulevelheader {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 1.5em;
}

.am-menuContentWrap {
    position: absolute;
    width: 100%;
    padding-bottom: 100px;
}

.am-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
}

.am-menu ul {
    padding-left: 0.7em;
}

.am-menu ul li > a,
.mp-menu ul li > a,
.currencySelectorMobile li > .currencyOption {
    color: #fff;
    display: block;
    padding: 15px 20px;
    outline: none;
    box-shadow: inset 0 -1px rgba(200, 200, 200, 0.3);
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
    font-size: 1.3em;
}

.am-menu ul li.mmHasChild > a,
.mp-menu ul li.mmHasChild > a {
    padding: 15px 40px 15px 20px;
}

.am-menu ul li > a,
.shop > ul > li > a {
    color: #333;
}

.am-menu .menuitemtext,
.mp-menu .menuitemtext {
    width: 100%;
}

.am-menu .has-dropdown > a > .menuitemtext,
.mp-menu .has-dropdown > a > .menuitemtext {
    width: calc(100% - 30px);
}

.am-menu .am-level a.menuitemtext {
    position: relative;
}

li.has-dropdown > a.MobMenChevron::after,
li.MenusaleRed > a.MobMenChevron::after {
    content: "";
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    top: 15px;
    right: 20px;
    padding: 0;
    font-size: 11px;
    box-sizing: border-box;
}

.am-menu .am-level a.MobMenChevron::after,
.mp-menu .mp-level a.MobMenChevron::after {
    background-image: url("/images/core/chevron-sprite.svg");
    background-size: 250px 250px;
    background-position: -110px -75px;
}

#liMobileLanguageSelector .glyphicon-chevron-right::before,
#liMobileCurrencySelector .glyphicon-chevron-right::before {
    content: "";
}

.am-menu .am-level a.MobMenChevron.rotate-90::after {
    transform: rotate(90deg);
    transition: transform 0.25s linear;
}

.glyphicon-chevron-right.rotate-90 {
    transform: rotate(90deg);
    transition: transform 0.25s linear;
    transition-delay: 1s;
}

.mp-menu ul li::before {
    position: absolute;
    left: 10px;
    z-index: -1;
    color: rgba(0, 0, 0, 0.2);
    line-height: 3.5;
}

.mp-menu .mp-level.mp-level-overlay > ul > li > a,
.mp-level.mp-level-overlay > ul > li:first-child > a {
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
}

.shop > ul > li > a {
    background-color: #fff;
    cursor: pointer;
}

.shop > ul > li#liAllDepts a {
    color: #fff !important;
    background-color: #333 !important;
}

.shop > ul > li#liAllDepts a span {
    color: #fff !important;
}

.shop > ul > li a.mp-back {
    background: rgba(0, 0, 0, 0.1);
    outline: none;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    display: block;
    font-size: 0.8em;
    padding: 1em;
    position: relative;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.1);
}

.shop > ul > li a.mp-back::after {
    font-family: "Glyphicons Halflings", sans-serif;
    position: absolute;
    content: "\e080";
    right: 10px;
    font-size: 1.3em;
    color: #fff;
    top: 7px;
}

.shop > ul > li .menulevelheader > a {
    color: #fff;
    background-color: transparent;
}

.mp-menu .mp-level.mp-level-overlay > .mp-back,
.mp-menu .mp-level.mp-level-overlay > .mp-back::after {
    background: transparent;
    box-shadow: none;
    color: transparent;
}

#MobtxtSearch {
    height: 33px;
    width: calc(100% - 33px);
    float: left;
    padding: 0 10px;
    border: 0;
}

#MobcmdSearch::before {
    content: "\e003";
}

#MobcmdSearch {
    font-family: "Glyphicons Halflings", sans-serif;
    font-size: 2em;
    float: right;
    width: 15%;
    max-width: 33px;
    line-height: 33px;
    text-align: center;
}

.MobileMenuContentWrap .dvSearch .TextBoxClear {
    right: 43px;
}

#divMobSearch .dvSearch {
    border: 1px solid #ddd;
    width: calc(100% - 45px);
    float: right;
    padding: 0;
}

#ModSearchClose {
    cursor: pointer;
    height: 35px;
    max-width: 35px;
    width: 15%;
    padding: 0;
}

#divMobSearch {
    z-index: 5900;
    display: none;
    position: fixed;
    width: 100%;
    top: 0;
    overflow-y: hidden;
}

#divMobSearch.open {
    display: block;
}

.dvSearchWrap {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    margin: 0;
}

.MobSearchDropDown {
    width: 75% !important;
    max-width: 280px;
    border-radius: 0 !important;
}

.MobSearchDropDown > li > a {
    font-size: 1.2em;
}

.MobSearchDropDown > li {
    color: #333;
}

.mp-level-open,
.mp-level-opening {
    display: block;
}

#subMenu {
    background-color: #f7f3f7;
    border: 1px solid #ccc;
    height: auto;
    width: 100% !important;
}

ul.sidebar-nav li.topic {
    display: block;
}

#subMenu a {
    display: block;
    font-size: 1em !important;
    padding: 16px 8px;
}

#subMenu a:hover,
#subMenu a:active {
    color: #565656;
}

ul.sidebar-nav li.topic:hover,
ul.sidebar-nav li.topic:active {
    background: none repeat scroll 0 0 #e6e4e6;
    position: relative;
}

@media (max-width: 767px) {
    #subMenu {
        margin-top: 10px;
    }
}

#topLinkMenu {
    height: 100%;
}

#topLinkMenu > ul {
    width: 100%;
    height: 100%;
}

#topLinkMenu ul li {
    float: left;
    padding: 0;
    text-align: left;
    min-width: 0;
    clear: none;
}

#topLinkMenu ul.TopLinkMenu li a {
    display: block;
}

#topLinkMenu ul > li {
    background-color: transparent;
    height: 100%;
    line-height: 3em;
    margin: 0;
    width: 33.33%;
}

#topLinkMenu ul li a:hover {
    background-color: #eee;
    color: #636363;
}

#topLinkMenu ul > li a {
    padding: 0 10px 0 30px;
}

#topLinkMenu span.ico {
    display: none;
}

@media (min-width: 1022px) {
    #topLinkMenu ul > li a {
        transition: background-color 0.25s linear 0s;
    }
}

div.NewMenu > ul > li {
    display: inline-block;
    position: relative;
}

div.NewMenu > ul {
    text-align: left;
    color: #fff;
}

div.NewMenu > ul > li > a {
    color: #fff;
    display: block;
    padding: 0 20px;
}

div.NewMenuWrap div.LinkLevel2 ul li.level1 div.DropdownWrap:hover {
    display: table-cell !important;
}

div.NewMenuWrap:hover {
    display: table !important;
}

div.NewMenuWrap {
    position: fixed;
    width: 100%;
    left: 0;
    background-color: #044f9c;
    height: 35px;
    display: none;
    overflow: inherit !important;
    z-index: 5200 !important;
}

div.NewMenuWrap ul > li.level1:hover {
    background-color: #0555a7 !important;
}

@media (max-width: 1021px) {
    .ResponsiveHeaderWrap div.NewMenuWrap {
        position: relative;
        width: 1022px;
        left: -10px;
    }

    .mp-pusher {
        transition: all 400ms linear 0s;
    }

    .mp-level {
        display: block;
    }
}

div.NewMenu {
    margin: 0 auto;
    max-width: 1210px;
    float: none;
    position: relative;
    padding: 0 10px;
    text-align: left;
}

.DropdownWrap {
    position: fixed;
    left: 0;
    display: none;
    z-index: 5200 !important;
    overflow: visible !important;
}

nav#topMenu .disablelink,
.am-menu .disablelink {
    cursor: text;
}

nav#topMenu li.sdmColHeaderHidden {
    padding-top: 24px;
}

nav#topMenu .DropdownWrap li.sdmColHeaderHidden {
    padding-top: 27px;
}

nav#topMenu ul,
nav li {
    padding: 0;
    margin: 0;
    list-style: none;
    list-style-type: none;
}

nav#topMenu > ul {
    display: block;
    height: 35px;
    margin: 0;
    padding: 0;
    width: 100%;
}

nav#topMenu > ul > li {
    float: left;
    display: inline-block;
}

nav#topMenu > ul li {
    padding: 0;
    margin: 0;
    list-style: none;
    font-weight: bold;
}

nav#topMenu > ul li.featuresection,
.shop > ul > li.mmHasChild > .am-level > ul > li.featuresection {
    display: none !important;
}

nav#topMenu > ul li a {
    font-size: 1.3em;
    line-height: 34px;
}

nav#topMenu li.root {
    position: relative;
    width: auto;
    float: left;
    text-transform: uppercase;
    height: 35px;
}

nav#topMenu li.root > a {
    text-decoration: none;
    padding: 0 24px;
    font-weight: normal;
    display: block;
}

nav#topMenu li.root > ul,
nav#topMenu li.root .SubMenuWrapper {
    display: block !important;
    position: absolute;
    top: 35px;
    left: 0;
    z-index: 5200 !important;
    font-size: 15px;
    padding: 8px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    visibility: hidden;
    transition:
        opacity 0.4s ease 0s,
        visibility 0.4s linear 0s;
    opacity: 0;
}

nav#topMenu ul li.mmHasChild.sdHover > ul,
nav#topMenu ul li.mmHasChild.sdHover .SubMenuWrapper {
    visibility: visible;
    opacity: 1;
}

nav#topMenu li.root .SubMenuWrapper li.Top,
nav#topMenu li.root .SubMenuWrapper li.Left {
    float: left;
}

nav#topMenu li.root .SubMenuWrapper li.Right,
nav#topMenu li.root .SubMenuWrapper li.Bottom {
    float: right;
}

nav#topMenu ul li.root .SubMenuWrapper > ul > li.Center > ul::before,
nav#topMenu ul li.root .SubMenuWrapper > ul > li.Center > ul::after {
    content: "";
    display: table;
}

nav#topMenu ul li.root .SubMenuWrapper > ul > li.Center > ul::after {
    clear: both;
}

nav#topMenu > ul li.columnGroup {
    float: left;
    padding: 0;
    margin: 0;
}

nav#topMenu li.columnGroup li.level1 {
    border-right: 1px solid #eee;
}

nav#topMenu li.columnGroup a {
    display: block;
    width: 100%;
    padding-right: 20px;
}

nav#topMenu ul.RootGroup {
    display: inline-block;
}

nav#topMenu li.root > ul li a,
nav#topMenu li.root .SubMenuWrapper > ul li a {
    font-size: 0.8em;
    line-height: 1.3em;
    width: 100%;
    padding: 3px 6px;
    text-transform: none;
    display: block;
    font-weight: normal;
}

nav#topMenu li.root > ul li a:hover,
nav#topMenu li.root .SubMenuWrapper > ul li a:hover {
    text-decoration: none;
}

nav#topMenu li.root > ul .sdmColHeader > a,
nav#topMenu li.root > ul .sdmColSaleHeader > a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColHeader > a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleHeader > a {
    font-weight: bold;
    font-size: 0.9em !important;
    min-height: 24px;
}

nav#topMenu li.root > ul .sdmColSaleHeader > a,
nav#topMenu li.root > ul .sdmColSaleFooter > a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleHeader > a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleFooter > a {
    color: #ed0000;
}

nav#topMenu li.root > ul .sdmColSaleFooter a,
nav#topMenu li.root > ul .sdmColFooter a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleFooter a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColFooter a {
    font-size: 0.8em !important;
    font-weight: bold;
}

nav#topMenu li.root > ul .sdmColOther > a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColOther > a {
    border-top: 1px dashed #ccc;
    margin-top: 5px;
    padding-top: 10px;
    font-size: 0.8em !important;
    font-weight: bold;
}

nav#topMenu li.root > ul .sdmColSubHeader,
nav#topMenu > ul .NewMenuWrap li.sdmColSubHeader,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColSubHeader {
    padding-top: 10px;
}

nav#topMenu li.root > ul .sdmColTopHeader > a,
nav#topMenu li.root > ul .sdmColSubHeader > a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColTopHeader > a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColSubHeader > a {
    font-size: 0.9em !important;
    font-weight: bold;
}

nav#topMenu > ul .NewMenuWrap li.sdmColHeader,
nav#topMenu > ul .NewMenuWrap li.sdmColSubHeader,
nav#topMenu > ul .NewMenuWrap li.sdmColHeaderHidden {
    border-bottom: 2px solid #bbb;
    margin-bottom: 6px;
}

nav#topMenu > ul .NewMenuWrap li.MarginColumn {
    margin-left: 20px;
}

nav#topMenu > ul .NewMenuWrap li.level2 a {
    line-height: 1.3em;
    font-size: 1.1em;
    padding: 3px 0;
    display: block;
}

nav#topMenu > ul .NewMenuWrap li.sdmColOther.level2 a {
    margin-top: 10px;
    font-weight: bold;
}

nav#topMenu > ul .NewMenuWrap li.TopGap {
    margin-top: 29px;
    border-top: 2px solid #bbb;
    padding-top: 6px;
}

nav#topMenu > ul .NewMenuWrap li.sdmColHeader a,
nav#topMenu > ul .NewMenuWrap li.sdmColSubHeader a {
    font-size: 1.3em;
    font-weight: bold;
    padding-bottom: 8px;
}

nav#topMenu li.root > ul .sdmColFooter,
nav#topMenu li.root > ul .sdmColSaleFooter,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColFooter,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleFooter {
    border-top: 1px dashed #ccc;
    font-weight: bold;
    padding: 2px 0 0;
}

nav#topMenu li.root > ul .sdmColDropHead a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColDropHead a {
    margin-top: 15px;
}

nav#topMenu > ul li.level1 a,
nav#topMenu > ul li.level2 a {
    min-width: 161px;
    max-width: 177px;
}

nav#topMenu li.root ul li ul li ul li ul {
    border-top: 1px solid #ccc;
}

nav#topMenu > ul li.HomeIcon a {
    font-size: 0.1em !important;
}

nav#topMenu li.root > ul .colParentBrand,
nav#topMenu li.root .SubMenuWrapper > ul .colParentBrand {
    border: medium none;
    float: left;
    width: 656px;
}

nav#topMenu li.root > ul .ClearanceBrands,
nav#topMenu li.root .SubMenuWrapper > ul .ClearanceBrands {
    width: 492px;
}

nav#topMenu li.root > ul .colParentBrand > a,
nav#topMenu li.root .SubMenuWrapper > ul .colParentBrand > a {
    height: 10px;
}

nav#topMenu li.root > ul .colParentBrand > ul,
nav#topMenu li.root .SubMenuWrapper > ul .colParentBrand > ul {
    border: none;
}

nav#topMenu li.root > ul .colParentBrand > ul li,
nav#topMenu li.root .SubMenuWrapper > ul .colParentBrand > ul li {
    float: left;
    height: 45px;
    width: 82px;
}

nav#topMenu li.root > ul .colParentBrand > ul li a,
nav#topMenu li.root .SubMenuWrapper > ul .colParentBrand > ul li a {
    background: url("/images/marketing/all-menu-logos.jpg") no-repeat scroll left top transparent;
    float: left;
    font-size: 0 !important;
    height: 45px;
    min-width: 0;
    padding: 0;
    width: 82px;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand1 a {
    background-position: 0 0;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand1 a:hover,
nav#topMenu li.root .MensBrands > ul li.MenuBrand1 a:focus,
nav#topMenu li.root .MensBrands > ul li.MenuBrand1 a:active {
    background-position: 0 -45px;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand2 a {
    background-position: -82px 0;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand2 a:hover,
nav#topMenu li.root .MensBrands > ul li.MenuBrand2 a:focus,
nav#topMenu li.root .MensBrands > ul li.MenuBrand2 a:active {
    background-position: -82px -45px;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand3 a {
    background-position: -164px 0;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand3 a:hover,
nav#topMenu li.root .MensBrands > ul li.MenuBrand3 a:focus,
nav#topMenu li.root .MensBrands > ul li.MenuBrand3 a:active {
    background-position: -164px -45px;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand4 a {
    background-position: -246px 0;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand4 a:hover,
nav#topMenu li.root .MensBrands > ul li.MenuBrand4 a:focus,
nav#topMenu li.root .MensBrands > ul li.MenuBrand4 a:active {
    background-position: -246px -45px;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand5 a {
    background-position: -328px 0;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand5 a:hover,
nav#topMenu li.root .MensBrands > ul li.MenuBrand5 a:focus,
nav#topMenu li.root .MensBrands > ul li.MenuBrand5 a:active {
    background-position: -328px -45px;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand6 a {
    background-position: -410px 0;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand6 a:hover,
nav#topMenu li.root .MensBrands > ul li.MenuBrand6 a:focus,
nav#topMenu li.root .MensBrands > ul li.MenuBrand6 a:active {
    background-position: -410px -45px;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand7 a {
    background-position: -492px 0;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand7 a:hover,
nav#topMenu li.root .MensBrands > ul li.MenuBrand7 a:focus,
nav#topMenu li.root .MensBrands > ul li.MenuBrand7 a:active {
    background-position: -492px -45px;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand8 a {
    background-position: -574px 0;
}

nav#topMenu li.root .MensBrands > ul li.MenuBrand8 a:hover,
nav#topMenu li.root .MensBrands > ul li.MenuBrand8 a:focus,
nav#topMenu li.root .MensBrands > ul li.MenuBrand8 a:active {
    background-position: -574px -45px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand1 a {
    background-position: 0 -90px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand1 a:hover,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand1 a:focus,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand1 a:active {
    background-position: 0 -135px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand2 a {
    background-position: -82px -90px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand2 a:hover,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand2 a:focus,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand2 a:active {
    background-position: -82px -135px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand3 a {
    background-position: -164px -90px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand3 a:hover,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand3 a:focus,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand3 a:active {
    background-position: -164px -135px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand4 a {
    background-position: -246px -90px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand4 a:hover,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand4 a:focus,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand4 a:active {
    background-position: -246px -135px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand5 a {
    background-position: -328px -90px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand5 a:hover,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand5 a:focus,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand5 a:active {
    background-position: -328px -135px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand6 a {
    background-position: -410px -90px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand6 a:hover,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand6 a:focus,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand6 a:active {
    background-position: -410px -135px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand7 a {
    background-position: -492px -90px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand7 a:hover,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand7 a:focus,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand7 a:active {
    background-position: -492px -135px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand8 a {
    background-position: -574px -90px;
}

nav#topMenu li.root .WomensBrands > ul li.MenuBrand8 a:hover,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand8 a:focus,
nav#topMenu li.root .WomensBrands > ul li.MenuBrand8 a:active {
    background-position: -574px -135px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand1 a {
    background-position: 0 -180px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand1 a:hover,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand1 a:focus,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand1 a:active {
    background-position: 0 -225px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand2 a {
    background-position: -82px -180px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand2 a:hover,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand2 a:focus,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand2 a:active {
    background-position: -82px -225px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand3 a {
    background-position: -164px -180px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand3 a:hover,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand3 a:focus,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand3 a:active {
    background-position: -164px -225px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand4 a {
    background-position: -246px -180px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand4 a:hover,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand4 a:focus,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand4 a:active {
    background-position: -246px -225px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand5 a {
    background-position: -328px -180px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand5 a:hover,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand5 a:focus,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand5 a:active {
    background-position: -328px -225px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand6 a {
    background-position: -410px -180px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand6 a:hover,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand6 a:focus,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand6 a:active {
    background-position: -410px -225px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand7 a {
    background-position: -492px -180px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand7 a:hover,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand7 a:focus,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand7 a:active {
    background-position: -492px -225px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand8 a {
    background-position: -574px -180px;
}

nav#topMenu li.root .KidsBrands > ul li.MenuBrand8 a:hover,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand8 a:focus,
nav#topMenu li.root .KidsBrands > ul li.MenuBrand8 a:active {
    background-position: -574px -225px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand1 a {
    background-position: 0 -270px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand1 a:hover,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand1 a:focus,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand1 a:active {
    background-position: 0 -315px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand2 a {
    background-position: -82px -270px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand2 a:hover,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand2 a:focus,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand2 a:active {
    background-position: -82px -315px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand3 a {
    background-position: -164px -270px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand3 a:hover,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand3 a:focus,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand3 a:active {
    background-position: -164px -315px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand4 a {
    background-position: -246px -270px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand4 a:hover,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand4 a:focus,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand4 a:active {
    background-position: -246px -315px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand5 a {
    background-position: -328px -270px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand5 a:hover,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand5 a:focus,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand5 a:active {
    background-position: -328px -315px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand6 a {
    background-position: -410px -270px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand6 a:hover,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand6 a:focus,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand6 a:active {
    background-position: -410px -315px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand7 a {
    background-position: -492px -270px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand7 a:hover,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand7 a:focus,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand7 a:active {
    background-position: -492px -315px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand8 a {
    background-position: -574px -270px;
}

nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand8 a:hover,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand8 a:focus,
nav#topMenu li.root ul.Football .FootballLogos > ul li.MenuBrand8 a:active {
    background-position: -574px -315px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand1 a {
    background-position: 0 -360px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand1 a:hover,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand1 a:focus,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand1 a:active {
    background-position: 0 -405px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand2 a {
    background-position: -82px -360px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand2 a:hover,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand2 a:focus,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand2 a:active {
    background-position: -82px -405px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand3 a {
    background-position: -164px -360px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand3 a:hover,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand3 a:focus,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand3 a:active {
    background-position: -164px -405px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand4 a {
    background-position: -246px -360px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand4 a:hover,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand4 a:focus,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand4 a:active {
    background-position: -246px -405px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand5 a {
    background-position: -328px -360px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand5 a:hover,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand5 a:focus,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand5 a:active {
    background-position: -328px -405px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand6 a {
    background-position: -410px -360px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand6 a:hover,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand6 a:focus,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand6 a:active {
    background-position: -410px -405px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand7 a {
    background-position: -492px -360px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand7 a:hover,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand7 a:focus,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand7 a:active {
    background-position: -492px -405px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand8 a {
    background-position: -574px -360px;
}

nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand8 a:hover,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand8 a:focus,
nav#topMenu li.root ul.Accessories .AccessoriesBrands > ul li.MenuBrand8 a:active {
    background-position: -574px -405px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand1 a {
    background-position: 0 -450px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand1 a:hover,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand1 a:focus,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand1 a:active {
    background-position: 0 -495px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand2 a {
    background-position: -82px -450px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand2 a:hover,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand2 a:focus,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand2 a:active {
    background-position: -82px -495px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand3 a {
    background-position: -164px -450px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand3 a:hover,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand3 a:focus,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand3 a:active {
    background-position: -164px -495px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand4 a {
    background-position: -246px -450px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand4 a:hover,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand4 a:focus,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand4 a:active {
    background-position: -246px -495px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand5 a {
    background-position: -328px -450px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand5 a:hover,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand5 a:focus,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand5 a:active {
    background-position: -328px -495px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand6 a {
    background-position: -410px -450px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand6 a:hover,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand6 a:focus,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand6 a:active {
    background-position: -410px -495px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand7 a {
    background-position: -492px -450px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand7 a:hover,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand7 a:focus,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand7 a:active {
    background-position: -492px -495px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand8 a {
    background-position: -574px -450px;
}

nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand8 a:hover,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand8 a:focus,
nav#topMenu li.root ul.Brands .BrandsLogos > ul li.MenuBrand8 a:active {
    background-position: -574px -495px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand1 a {
    background-position: 0 -540px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand1 a:hover,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand1 a:focus,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand1 a:active {
    background-position: 0 -585px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand2 a {
    background-position: -82px -540px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand2 a:hover,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand2 a:focus,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand2 a:active {
    background-position: -82px -585px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand3 a {
    background-position: -164px -540px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand3 a:hover,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand3 a:focus,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand3 a:active {
    background-position: -164px -585px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand4 a {
    background-position: -246px -540px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand4 a:hover,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand4 a:focus,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand4 a:active {
    background-position: -246px -585px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand5 a {
    background-position: -328px -540px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand5 a:hover,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand5 a:focus,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand5 a:active {
    background-position: -328px -585px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand6 a {
    background-position: -410px -540px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand6 a:hover,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand6 a:focus,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand6 a:active {
    background-position: -410px -585px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand7 a {
    background-position: -492px -540px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand7 a:hover,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand7 a:focus,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand7 a:active {
    background-position: -492px -585px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand8 a {
    background-position: -574px -540px;
}

nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand8 a:hover,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand8 a:focus,
nav#topMenu li.root .OutdoorBrands > ul li.MenuBrand8 a:active {
    background-position: -574px -585px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand1 a {
    background-position: 0 -630px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand1 a:hover,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand1 a:focus,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand1 a:active {
    background-position: 0 -675px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand2 a {
    background-position: -82px -630px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand2 a:hover,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand2 a:focus,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand2 a:active {
    background-position: -82px -675px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand3 a {
    background-position: -164px -630px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand3 a:hover,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand3 a:focus,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand3 a:active {
    background-position: -164px -675px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand4 a {
    background-position: -246px -630px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand4 a:hover,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand4 a:focus,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand4 a:active {
    background-position: -246px -675px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand5 a {
    background-position: -328px -630px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand5 a:hover,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand5 a:focus,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand5 a:active {
    background-position: -328px -675px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand6 a {
    background-position: -410px -630px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand6 a:hover,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand6 a:focus,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand6 a:active {
    background-position: -410px -675px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand7 a {
    background-position: -492px -630px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand7 a:hover,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand7 a:focus,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand7 a:active {
    background-position: -492px -675px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand8 a {
    background-position: -574px -630px;
}

nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand8 a:hover,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand8 a:focus,
nav#topMenu li.root .ClearanceBrands > ul li.MenuBrand8 a:active {
    background-position: -574px -675px;
}

#MenuOpenContentCover {
    z-index: 5100;
    visibility: hidden;
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
}

#MenuOpenContentCover.activeOverlay {
    display: block;
    visibility: visible;
    opacity: 0.6;
    transition-delay: 0.25s;
    pointer-events: auto;
}

@media (min-width: 768px) and (max-width: 1021px) {
    #topLinkMenu ul > li a {
        padding: 8px 10px 0 30px;
    }

    #topLinkMenu ul li:hover {
        background-color: transparent !important;
    }

    nav#topMenu li.root > ul li a,
    nav#topMenu li.root .SubMenuWrapper > ul li a {
        padding: 6px;
    }

    nav#topMenu li.root > ul .sdmColHeader > a,
    nav#topMenu li.root > ul .sdmColSaleHeader > a,
    nav#topMenu li.root .SubMenuWrapper > ul .sdmColHeader > a,
    nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleHeader > a {
        min-height: 31px;
    }

    nav#topMenu li.sdmColHeaderHidden {
        padding-top: 31px;
    }
}

nav#topMenu > ul li .singlecolumnadvert .HeaderAdvert {
    font-size: 1em;
    padding: 10px 0;
    font-weight: bold;
    line-height: 1.3em;
}

nav#topMenu > ul li .singlecolumnadvert span {
    font-size: 1em;
    color: #888;
    display: block;
}

nav#topMenu > ul li .singlecolumnadvert img {
    width: 100%;
}

nav#topMenu > ul li .singlecolumnadvert .view-button {
    display: block;
    font-size: 0.9em;
}

nav#topMenu > ul li .singlecolumnadvert .view-button a {
    font-weight: bold !important;
    padding: 10px 0 !important;
}

.Dropdown {
    width: 100%;
    background-color: #fff;
    margin: 0;
    max-width: 1210px;
    padding: 0 10px;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    box-shadow: 0 0 10px 1px #888;
    margin-bottom: 10px;
}

.Dropdown .Center {
    width: 50%;
    float: left;
    padding: 15px;
}

.AutoWidth .Center {
    width: 100%;
}

.Dropdown .Center .columnGroup {
    width: 33.33%;
}

.Dropdown .Right {
    width: 50%;
    float: right;
    padding-left: 15px;
    padding-top: 15px;
}

.Dropdown .Right .singlecolumnadvert {
    width: 33.33%;
    float: left;
    padding: 10px;
}

nav#topMenu ul li.root.FullWidthTraditional > ul {
    position: fixed;
    top: 110px;
}

#topMenu .Picker.tabs.ui-tabs.ui-widget.ui-widget-content.ui-corner-all.ui-tabs-vertical {
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 15px;
}

#topMenu .Picker.tabs ul.ui-tabs-nav {
    width: 100%;
    height: 100%;
    padding-top: 5px;
}

#topMenu .Picker.tabs ul.ui-tabs-nav li {
    background-color: #fff;
    margin: 0 0 0 10px;
    padding: 0 10px !important;
}

#topMenu .Picker.tabs ul.ui-tabs-nav li:hover {
    background-color: #f5f5f5;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 1px solid #d3d3d3;
    border-right: 0;
    width: 100%;
}

#topMenu .Picker.tabs ul.ui-tabs-nav li a {
    color: #000;
    border: 0;
    background-color: inherit;
}

#topMenu .Picker.tabs ul.ui-tabs-nav li a:hover {
    color: #008d7f;
}

#topMenu .Picker.tabs .ui-tabs-panel {
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    padding: 10px;
    box-shadow: inset -6px -5px 10px -6px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 5px !important;
}

#topMenu .Picker.tabs {
    position: relative;
    padding: 0;
    border-radius: 0 0 5px 5px !important;
}

nav#topMenu > ul .NewMenuWrap li.level1 a {
    min-width: 0;
}

.Picker.Picker.tabs {
    border: solid 1px #d3d3d3;
    padding: 5px;
    border-top: 0;
}

.Picker.PickerHeader {
    box-shadow: inset 1px 1px 8px 1px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid #b9b9b9;
    height: 40px;
    padding: 12px;
    margin-top: 5px;
    border-radius: 5px 5px 0 0;
}

.PickerLeft {
    width: 50%;
    height: 100%;
    position: absolute;
    float: left;
    box-shadow: inset 2px -3px 10px -2px rgba(0, 0, 0, 0.2);
    border-right: solid 1px #d3d3d3;
}

.PickerRight {
    height: 100%;
    width: 50%;
    float: right;
    border-radius: 0 0 5px !important;
}

.SideMenuWrapper {
    position: fixed;
    z-index: 100;
    padding-left: 0;
    padding-right: 23px;
}

.SideMenuWrapper .panel {
    box-shadow: none;
}

#SideMenuWrapper .NaR {
    border-top: solid 1px #ddd !important;
}

#SideMenuWrapper {
    position: fixed;
    z-index: 100;
    padding-left: 0;
    padding-right: 15px;
    width: 16%;
    background-color: transparent;
    margin: 0 auto;
}

#SideMenuWrapper .mmHasChild {
    font-weight: bold;
    color: #000400;
}

#SideMenuWrapper .panel li :hover {
    text-decoration: underline;
    font-weight: bold;
}

#SideMenuWrapper .panel-heading,
.panel-group,
.panel {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    background-color: #fff;
    border-top: 0;
}

#SideMenuWrapper .panel {
    border-radius: 0;
    border-color: #e1e1e1;
    margin-top: 0;
    text-transform: uppercase;
    font-size: 1.1em;
}

#SideMenuWrapper .panel a {
    display: block;
    color: #000400;
    font-weight: bold;
    font-size: 1.1em;
    padding: 9px 14px;
}

#SideMenuWrapper .panel-collapse {
    padding: 0 14px 12px 0;
    width: auto;
}

#SideMenuWrapper .panel .level1 {
    border: none;
    font-size: 0.9em;
    text-transform: capitalize !important;
    padding: 3px 0;
}

#SideMenuWrapper .panel .level1.featuresection {
    display: none;
}

#SideMenuWrapper .panel .level1 a {
    color: #616161;
    font-weight: normal;
    padding: 1px 14px;
}

#SideMenuWrapper .panel .level1.selected a {
    font-weight: bold;
    color: #000400;
}

#SideMenuWrapper .panel .level1 a:hover {
    color: #000400;
    font-weight: normal;
}

nav#topMenu > ul .NewMenuWrap li.colBrands {
    margin-left: 0;
    margin-top: 10px;
}

.Dropdown .Center > ul > li:first-child + li + li + li {
    width: 100%;
    clear: both;
}

nav#topMenu > ul .NewMenuWrap li.colBrandsItem {
    float: left;
    width: 33.33%;
}

@media (min-width: 895px) and (max-width: 1021px) {
    .Dropdown .Center {
        width: 65%;
    }

    .AutoWidth .Center {
        width: 100%;
    }

    .Dropdown .Right {
        width: 35%;
    }

    .Dropdown .Right .singlecolumnadvert {
        width: 50%;
    }

    .Dropdown .Right .lastAd {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    nav#topMenu li.root > ul li a,
    nav#topMenu li.root .SubMenuWrapper > ul li a {
        padding: 8px 6px;
    }

    nav#topMenu li.root > ul .sdmColHeader > a,
    nav#topMenu li.root > ul .sdmColSaleHeader > a,
    nav#topMenu li.root .SubMenuWrapper > ul .sdmColHeader > a,
    nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleHeader > a {
        min-height: 33px;
    }

    nav#topMenu li.sdmColHeaderHidden {
        padding-top: 33px;
    }

    nav#topMenu > ul .NewMenuWrap li.level2 a {
        font-size: 1.1em;
        padding: 8px 0;
    }

    nav#topMenu > ul .NewMenuWrap li.sdmColHeader a {
        font-size: 1.2em;
    }

    nav#topMenu > ul .NewMenuWrap li.TopGap {
        margin-top: 34px;
    }
}

@media (min-width: 768px) and (max-width: 894px) {
    .Dropdown .Center {
        width: 100%;
    }

    .Dropdown .Right {
        display: none;
    }
}

.accordionMenuContentWrap {
    position: absolute;
    width: 100%;
    padding-bottom: 141px;
}

.accordionMenuContentWrap .MobMenIcon {
    padding: 0 10px 0 0;
    font-size: 0.8em;
}

.accordionMenuContentWrap .glyphicon-chevron-right {
    padding: 0;
}

.accordionMenuContentWrap > ul li,
.accordionMenuContentWrap #liMobileLanguageSelector,
.accordionMenuContentWrap #liMobileCurrencySelector {
    background-color: #f6f6f6;
}

.accordionMenuContentWrap > ul li:hover,
.accordionMenuContentWrap #liMobileLanguageSelector:hover,
.accordionMenuContentWrap #liMobileCurrencySelector:hover {
    background-color: #f0f0f0;
}

.accordionMenuContentWrap .has-dropdown ul li {
    background-color: #fff;
}

.accordionMenuContentWrap #homeMenu {
    display: none !important;
}

.accordionMenuContentWrap .shop > ul > li.root > a,
.accordionMenuContentWrap .has-dropdown.root li.root > a,
.accordionMenuContentWrap .has-dropdown li.level1 > a,
.accordionMenuContentWrap .has-dropdown.open li.level2 > a {
    background-color: #fff;
}

.accordionMenuContentWrap .shop > ul > li.root.open > a,
.accordionMenuContentWrap .has-dropdown li.level1.open > a {
    background-color: #e4e4e4;
    box-shadow: inset 0 -1px rgba(0, 0, 0, 1);
}

.am-menu .accordionMenuContentWrap ul {
    padding-left: 0;
    cursor: pointer;
}

.accordionMenuContentWrap .clearfix {
    content: "";
    display: table;
    clear: both;
}

.accordionMenuContentWrap .glyphicon.glyphicon-chevron-right::before {
    font-size: 18px;
    display: inline-block;
    width: 20px;
    height: 20px;
}

.accordionMenuContentWrap .currencySelectorMobile li > .currencyOption {
    display: flex;
    background-color: #fff;
}

.currencySelectorMobile input[type="radio"] {
    order: 1;
    margin: 3px 0 0;
    display: inline-block;
}

.accordionMenuContentWrap .currencyOption label {
    color: #000;
    font-weight: normal;
    display: inline-block;
    padding-left: 10px;
    order: 2;
    margin: 0;
    font-size: 1em;
}

.accordionMenuContentWrap .am-menu .shop > ul > li > a {
    padding-left: 0;
}

.accordionMenuContentWrap .am-level ul {
    padding-left: 0;
}

.mp-close {
    display: none;
}

nav#topMenu li.root .SubMenuWrapper > ul > li.Center ul li.level1 ul li.Left.columnGroup,
nav#topMenu li.root .SubMenuWrapper > ul > li.Center ul li.level1 ul li.Right.columnGroup {
    display: none !important;
}

.mp-container .mp-menu {
    position: fixed;
    top: 45px;
    left: 0;
    z-index: 10000;
    width: 85%;
    height: 100%;
    max-width: 350px;
    min-width: 233px;
    transform: translate(-100%, 0);
    transition: 0.5s ease-in-out;
    overflow: hidden;
    visibility: hidden;
}

.mp-container .mp-menu.menu-open {
    visibility: visible;
}

.mp-container .mp-menu .has-dropdown .mp-level {
    visibility: hidden;
    transform: translate(100%, 0);
    transition:
        transform 0.5s ease-in-out,
        visibility 0s linear 0.5s;
    height: 100%;
}

.mp-container .mp-menu.menu-open,
.mp-container .mp-menu .has-dropdown .mp-level.show-level,
.mp-container .mp-menu #liMobileLanguageSelector .mp-level.show-level,
.mp-container .mp-menu #liMobileCurrencySelector .mp-level.show-level {
    visibility: visible;
    transform: translate(0, 0);
    transition:
        visibility 0s,
        transform 0.5s ease-in-out;
    height: 100%;
}

.mp-container .mp-menu .mp-level {
    max-height: calc(100vh - 45px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.mp-container .mp-menu .mp-level.child-open {
    overflow-y: hidden;
}

#liMobileLanguageSelector,
#liMobileCurrencySelector,
#dnn_mobLogin_loginLink {
    cursor: pointer;
}

@media (min-width: 768px) {
    .sidebarSubMenu .SubMenuWrapper {
        height: 450px;
    }

    .sidebarSubMenu .SubMenuWrapper > ul {
        position: relative;
    }

    .sidebarSubMenu .SubMenuWrapper > ul > li > ul {
        width: 25%;
    }

    nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul {
        overflow: visible;
    }

    nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 {
        display: block;
        float: none;
        width: 100%;
        height: 45px;
        border-bottom: solid 1px #ddd;
        border: solid 1px #fff;
    }

    nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 > ul {
        display: none;
        position: absolute;
        /*left position calculated from parent padding which includes percentages*/
        left: calc(23% - 35px);
        top: 10px;
        width: 75%;
    }

    nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1:hover > ul {
        padding: 10px 30px 0 100px;
        height: 400px;
        overflow-y: auto;
    }

    nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1:hover > ul > li > ul,
    nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 > ul li > ul:hover {
        display: inline-block;
        border: none;
    }

    nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper li.columnGroup {
        width: 25%;
    }
}

@media (max-width: 1021px) {
    .body-menu-open {
        overflow: hidden;
    }
}

.membership-menu-item {
    display: none;
}

.membership-enabled .membership-menu-item {
    display: block;
}
